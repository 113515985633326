.filter-panel {
    background-color: #F0F0F0;
    display: flex;
}

.filter-panel-header {
    padding: 5px;
    background-color: #CCCCCC;
}

.filter-panel-body {
    padding: 5px;
}

.filter-panel-body .filter-label {
    font-size: 12px;
}

.filter-panel-body .ant-radio-group-small .ant-radio-button-wrapper {
    height: 18px;
    line-height: 16px;
    font-size: 12px;
}
